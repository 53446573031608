<div class="search-interface row">
    <form ngForm="searchForm">
        <div class="form-group">
            <div class="form-control-name">Tech name</div>
            <input class="name-field"
                   type="text"
                   maxlength=50
                   [formControl]="nameField"
                   (keyup)="search()" />
        </div>

        <div class="form-group">
            <div class="form-control-name">Type</div>
            <select class="type-dropdown"
                    [formControl]="typeDropdown"
                    (change)="search()">
                <option *ngFor="let current of types"
                        value="{{current}}">
                    {{current}}
                </option>
            </select>
        </div>
        
        <div class="form-group">
            <div class="form-control-name">Classification</div>
            <select class="classification-dropdown"
                    [formControl]="classificationDropdown"
                    (change)="search()">
                <option *ngFor="let current of classifications"
                        value="{{current}}">
                    {{current}}
                </option>
            </select>
        </div>

        <div class="button-group">
            <button class="primary-action clear-button"
                    type="button"
                    (click)="clear()">
                Clear
            </button>
        </div>
    </form>
</div>