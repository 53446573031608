<div class="app-frame">
  <div class="row header-row padding-verti-standard margin-verti-standard">
    <app-logo class="logo"
              routerLink="/home"></app-logo>
    <div class="right-menu">
      <a class="menu-item" href="https://www.github.com/Asvarduil/PersonalSite" target="_blank">Source</a>
      <a class="menu-item" routerLink="/technology">Technologies</a>
      <a class="menu-item" routerLink="/cv">C.V.</a>
    </div>
  </div>

  <div class="row content-row padding-verti-standard margin-verti-standard">
    <router-outlet></router-outlet>
  </div>

  <div class="row copyright margin-verti-standard">
    ©2023, Andrew R. Gray.  All Rights Reserved
  </div>
</div>