<div class="cv-container">
  <p class="headline">Andrew Gray has worked a number of computer programming jobs, as seen below.</p>
  <p>Click a history posting to learn more.</p>
  
  <div *ngIf="!jobs || jobs.length === 0">
    Loading...please wait...
  </div>
  
  <div *ngIf="jobs && jobs.length > 0">
    <app-collapsible-panel [panelData]="getJobPanelData(current)"
      *ngFor="let current of jobs">
    </app-collapsible-panel>
  </div>
</div>